import Vue from "vue";
import Vuetify from "vuetify/lib";
import minifyTheme from "minify-css-string";
import es from "vuetify/es5/locale/es";

export default new Vuetify({
  lang: {
    locales: { es },
    current: "es"
  },
  theme: {
    options: {
      customProperties: true,
      minifyTheme
    },
    icons: {
      iconfont: "md" || "fa" || "mdi"
    },
    themes: {
      light: {
        primary: "#b155c1",
        secondary: "#62d5cc"
      }
    }
  }
});
Vue.use(Vuetify);
