import axios from "axios";
import errorHandler from "./error-handler";

const baseDomain = process.env.VUE_APP_VENUSA_LIVE_API;

const httpClient = axios.create({
  baseURL: baseDomain || "http://localhost:3000/api/v1",
  timeout: 300000
});

httpClient.interceptors.response.use(response => response.data, errorHandler);

export default httpClient;
