import httpClient from "../http-client";
const resource = "website";

export default {
  getRoute() {
    return `${process.env.VUE_APP_VENUSA_LIVE_API}/${resource}/file`;
  },
  getAllSectionsAndContents() {
    return httpClient.get(`${resource}/all-sections-contents`);
  },
  getWebsiteSectionById(websiteSectionId) {
    return httpClient.get(`${resource}/section/${Number(websiteSectionId)}`);
  },
  getAllSections() {
    return httpClient.get(`${resource}/all-sections`);
  },
  createContent(websiteContent) {
    return httpClient.post(`${resource}/content`, websiteContent);
  },
  uploadFile(file) {
    return httpClient.post(`${resource}/upload-file`, file);
  },
  deleteFile(filename) {
    return httpClient.delete(`${resource}/delete-file/${filename}`);
  },
  deleteContent(websiteContentId) {
    return httpClient.delete(`${resource}/content/${websiteContentId}`);
  },
  updateSection(websiteSection) {
    return httpClient.patch(`${resource}/section`, websiteSection);
  }
};
