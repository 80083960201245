import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueFormGenerator from "vue-form-generator";
import VueApexCharts from "vue-apexcharts";
import Hotjar from "vue-hotjar";
import VueGtag from "vue-gtag";
import "vue-form-generator/dist/vfg.css";

import TextInput from "@/components/forms/inputs/TextInput";
import TextAreaInput from "@/components/forms/inputs/TextAreaInput";
import SingleChoiceInput from "@/components/forms/inputs/SingleChoiceInput";
import MultipleChoiceInput from "@/components/forms/inputs/MultipleChoiceInput";
import DateInput from "@/components/forms/inputs/DateInput";
Vue.component("fieldCustometext", TextInput);
Vue.component("fieldCustometextarea", TextAreaInput);
Vue.component("fieldCustomesinglechoice", SingleChoiceInput);
Vue.component("fieldCustomemultiplechoice", MultipleChoiceInput);
Vue.component("fieldCustomedate", DateInput);

Vue.config.productionTip = false;
Vue.use(VueFormGenerator);
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
Vue.use(Hotjar, {
  id: process.env.VUE_APP_HOTJAR_ID,
  isProduction: true,
  snippetVersion: 6
});
Vue.use(
  VueGtag,
  {
    config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS }
  },
  router
);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
