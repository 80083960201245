import httpClient from "../http-client";
import jwt from "@/common/jwt.service";
const resource = "users";

export default {
  authorize(user) {
    return httpClient.post(`authorize`, user);
  },
  authorizeAlternative(user) {
    return httpClient.post(
      `authorize-alternative`,
      user,
      jwt.getAuthHeaderToken()
    );
  },
  sendForgottenPasswordEmail(email) {
    return httpClient.post(`${resource}/password-reset`, email);
  },
  sendAlternativeLoginEmail(email) {
    return httpClient.post(`${resource}/alternative`, email);
  },
  updatePassword(newPassword) {
    return httpClient.patch(
      `${resource}/password-reset`,
      newPassword,
      jwt.getAuthHeaderToken()
    );
  }
};
