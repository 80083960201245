<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    :disabled="schema.disabled"
    :readonly="schema.readonly"
    class="mr-12"
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model.trim="computedDateFormatted"
        append-icon="mdi-calendar"
        :disabled="disabled"
        :placeholder="schema.placeholder"
        readonly
        :rules="schema.required && !schema.adminView ? [rules.required] : []"
        v-bind="attrs"
        v-on="on"
        outlined
        clearable
        maxlength="100"
        hide-hint
        class="my-0"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      show-current-date
      class="date-input-style date-input-header-style date-input-current-style"
      no-title
      :readonly="schema.readonly"
      @input="menu = false"
      color="primary"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { abstractField } from "vue-form-generator";

export default {
  name: "date-input",
  mixins: [abstractField],
  data() {
    return {
      menu: false,
      date: null,
      rules: {
        required: value => !!value || "Campo Obligatorio"
      }
    };
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    }
  },
  computed: {
    computedDateFormatted: {
      get: function() {
        return this.formatDate(this.date);
      },
      set: function(newValue) {
        this.value = newValue;
      }
    }
  },
  watch: {
    computedDateFormatted(newValue) {
      this.value = newValue;
    }
  },
  mounted() {
    if (this.schema.startValue) {
      const [day, month, year] = this.schema.startValue.split("/");
      this.date = `${year}-${month}-${day}`;
    }
  }
};
</script>
<style scoped>
.date-input-style ::v-deep button {
  border: solid 0px;
  border-radius: 28px;
  font-size: 12px;
}

.date-input-header-style ::v-deep .v-date-picker-header__value ::v-deep button {
  font-size: 16px;
  font-weight: 700;
  color: var(--v-primary-base);
  background-color: white;
  box-shadow: 0px 0px 0px white;
}
.date-input-header-style ::v-deep .v-date-picker-table__current {
  border: thin solid var(--v-primary-base);
}
.date-input-current-style ::v-deep .v-date-picker-table .v-btn.v-btn--active {
  color: white;
}
</style>
