import Vue from "vue";
import VueRouter from "vue-router";
import jwt from "../common/jwt.service";
import store from "../store";

import { APP_SECTIONS, ROLES } from "../config/constants";

Vue.use(VueRouter);

const routes = [
  {
    path: "/therapies",
    name: "website-container-therapy",
    component: () =>
      import(
        /* webpackChunkName: "website-container-therapy" */ "@/views/websitePreview/WebsiteContainerTherapy.vue"
      )
  },
  {
    path: "/wall",
    name: "website-container-wall",
    component: () =>
      import(
        /* webpackChunkName: "website-container-wall" */ "@/views/websitePreview/WebsiteContainerWall.vue"
      )
  },
  {
    path: "/",
    name: "website-container-home",
    component: () =>
      import(
        /* webpackChunkName: "website-container-home" */ "@/views/websitePreview/WebsiteContainerHome.vue"
      )
  },
  {
    path: "/app/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login-view" */ "@/views/login/LoginView.vue")
  },
  {
    path: "/form",
    name: "patient-form",
    component: () =>
      import(
        /* webpackChunkName: "patient-form" */ "@/views/forms/PatientFormView.vue"
      )
  },
  {
    path: "/form-update/:id",
    name: "form-update",
    beforeEnter: (to, from, next) => {
      let token = to.params.id;
      jwt.setToken(token);
      if (jwt.isTokenValid()) {
        next({ name: "form-update-view" });
      } else {
        jwt.destroyToken();
        next({ name: "website-container-home" });
      }
    }
  },
  {
    path: "/form-update",
    name: "form-update-view",
    beforeEnter: (to, from, next) => {
      if (jwt.isTokenValid() && jwt.decodeToken().formPatient) {
        next();
      } else {
        jwt.destroyToken();
        next({ name: "website-container-home" });
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "form-update-view" */ "@/views/forms/PatientFormUpdateView.vue"
      )
  },
  {
    path: "/app/reset-password/:id",
    name: "reset-password",
    beforeEnter: (to, from, next) => {
      let token = to.params.id;
      jwt.setToken(token);
      if (jwt.isTokenValid()) {
        next({ name: "reset-password-view" });
      } else {
        jwt.destroyToken();
        next({ name: "login" });
      }
    }
  },
  {
    path: "/app/reset-password",
    name: "reset-password-view",
    beforeEnter: (to, from, next) => {
      if (jwt.isTokenValid() && jwt.decodeToken().resetPassword) {
        next();
      } else {
        jwt.destroyToken();
        next({ name: "login" });
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "reset-password-view" */ "@/views/resetPassword/ResetPasswordView.vue"
      )
  },
  {
    path: "/app/alternative-login/:id",
    name: "alternative-login",
    beforeEnter: (to, from, next) => {
      let token = to.params.id;
      jwt.setToken(token);

      if (jwt.isTokenValid()) {
        next({ name: "alternative-login-view" });
      } else {
        jwt.destroyToken();
        next({ name: "login" });
      }
    }
  },
  {
    path: "/app/alternative-login",
    name: "alternative-login-view",
    beforeEnter: (to, from, next) => {
      if (jwt.isTokenValid() && jwt.decodeToken().alternative) {
        next();
      } else {
        jwt.destroyToken();
        next({ name: "login" });
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "alternative-login-view" */ "@/views/alternativeLogin/AlternativeLoginView.vue"
      )
  },
  {
    path: "/app/admin",
    name: "layout",
    redirect: "app/admin/dashboard",
    component: () =>
      import(
        /* webpackChunkName: "layout" */ "@/components/common/navigation/Layout"
      ),
    meta: {
      requiresAdminAuth: true
    },
    children: [
      {
        path: "/app/admin/dashboard",
        name: "dashboard",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "@/views/dashboard/DashboardView.vue"
          ),
        meta: {
          app_section: APP_SECTIONS.DASHBOARD
        }
      },
      {
        path: "/app/admin/patients",
        name: "patients",
        component: () =>
          import(
            /* webpackChunkName: "patients" */ "@/views/patients/PatientsView.vue"
          ),
        meta: {
          app_section: APP_SECTIONS.PATIENTS
        }
      },
      {
        path: "/app/admin/patients/:id",
        name: "patients-detail",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "patients-detail" */ "@/views/patients/PatientsDetailView.vue"
          ),
        meta: {
          app_section: APP_SECTIONS.PATIENTS
        }
      },
      {
        path: "/app/admin/forms",
        name: "forms",
        component: () =>
          import(/* webpackChunkName: "forms" */ "@/views/forms/FormsView.vue"),
        meta: {
          app_section: APP_SECTIONS.FORMS
        }
      },
      {
        path: "/app/admin/website",
        name: "website",
        component: () =>
          import(
            /* webpackChunkName: "website" */ "@/views/website/WebsiteView.vue"
          ),
        meta: {
          app_section: APP_SECTIONS.WEBSITE
        }
      }
    ]
  },
  {
    path: "*",
    redirect: "/"
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  let user = store.getters["users/getUser"];
  const isTokenValid = !!(user && jwt.isTokenValid());
  const isUserAdmin = !!(user && user?.role.id === ROLES.ADMIN.id);
  if (to.matched.some(record => record.meta.requiresAdminAuth)) {
    // ADMIN URL PROTECTION
    if (
      isTokenValid &&
      isUserAdmin &&
      !jwt.decodeToken().resetPassword &&
      !jwt.decodeToken().alternative &&
      !jwt.decodeToken().formPatient
    ) {
      next();
    } else {
      next({ name: "website-container-home" });
    }
  } else {
    if (
      to.name === "login" &&
      isTokenValid &&
      isUserAdmin &&
      !jwt.decodeToken().resetPassword &&
      !jwt.decodeToken().alternative &&
      !jwt.decodeToken().formPatient
    ) {
      next({ name: "dashboard" });
    } else {
      next();
    }
  }
});

export default router;
