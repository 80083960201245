<template>
  <span class="full-width">
    <v-radio-group
      v-if="schema.options.length <= 5"
      v-model="option"
      class="mt-3"
      :rules="schema.required && !schema.adminView ? [rules.required] : []"
      :readonly="schema.readonly"
    >
      <v-radio
        :value="option"
        v-for="(option, index) in schema.options"
        :key="index + '.' + option.id"
        class="mb-6 "
        :label="option.name"
        color="primary"
      ></v-radio>
    </v-radio-group>
    <!-- Input for more than five options -->
    <v-autocomplete
      v-else
      :items="schema.options"
      item-text="name"
      item-vaue="id"
      v-model="option"
      :rules="schema.required && !schema.adminView ? [rules.required] : []"
      outlined
      :placeholder="schema.placeholder"
      return-object
    >
    </v-autocomplete>
  </span>
</template>

<script>
import { abstractField } from "vue-form-generator";

export default {
  name: "single-choice-input",
  mixins: [abstractField],
  data() {
    return {
      option: null,
      rules: {
        required: value => !!value || "Campo Obligatorio"
      }
    };
  },
  watch: {
    option(newValue) {
      this.value = [newValue];
    }
  },
  mounted() {
    if (this.schema.startValue) {
      let selectedOption = this.schema.options.find(
        option => option.id === this.schema.startValue[0].option.id
      );
      this.option = selectedOption;
    }
  }
};
</script>
<style scoped>
.full-width {
  width: 100%;
}
</style>
