import { VueEasyJwt } from "vue-easy-jwt";
import httpClient from "@/services/http-client";
import SecureLS from "secure-ls";

const vueEasyJwt = new VueEasyJwt();

const ls = new SecureLS({
  encryptionSecret: process.env.VUE_APP_ENCRYPTION_SECRET
});

const ID_TOKEN_KEY = "id_token";

export const getToken = () => {
  return ls.get(ID_TOKEN_KEY);
};

export const setToken = token => {
  ls.set(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  ls.remove(ID_TOKEN_KEY);
};

export const isTokenValid = () => {
  const token = getToken();
  return !!(token && !vueEasyJwt.isExpired(token));
};

export const getAuthHeaderToken = () => {
  return {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`
    }
  };
};

export const setAuthHeaderToken = () => {
  httpClient.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
};

export const decodeToken = () => {
  return vueEasyJwt.decodeToken(getToken());
};

export default {
  getToken,
  setToken,
  destroyToken,
  isTokenValid,
  getAuthHeaderToken,
  setAuthHeaderToken,
  decodeToken
};
