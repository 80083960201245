import Repository from "@/services/repositories/repositoryFactory";
import jwt from "../../common/jwt.service";
const UserRepository = Repository.get("users");

// Initial State object
const initialState = () => {
  return {
    user: null,
    roles: []
  };
};

// State object
const state = initialState();

// Getter functions
const getters = {
  getUser(state) {
    return state.user;
  },
  getRoles(state) {
    return state.roles;
  }
};

// Mutations
const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setRoles(state, roles) {
    state.roles = roles;
  },
  reset(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  }
};

// Actions
const actions = {
  async authorize({ commit }, user) {
    const response = await UserRepository.authorize(user);
    jwt.setToken(response.access_token);
    commit("setUser", response.user);
  },
  setUser({ commit }, response) {
    jwt.setToken(response.access_token);
    commit("setUser", response.user);
  },
  reset({ commit }) {
    commit("reset");
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
