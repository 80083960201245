import httpClient from "../http-client";
import jwt from "@/common/jwt.service";
const formResource = "forms";
const formPatientResource = "form_patient";
const questionResource = "questions";
const sectionResource = "sections";

export default {
  getFormById(formId) {
    return httpClient.get(`${formResource}/form/${formId}`);
  },
  getFormByIdForResponse(formId) {
    return httpClient.get(`${formResource}/active_form/${formId}`);
  },
  getQuestionTypes() {
    return httpClient.get(`${questionResource}/types`);
  },
  createQuestion(question) {
    return httpClient.post(`${questionResource}`, question);
  },
  updateQuestion(question) {
    return httpClient.patch(`${questionResource}`, question);
  },
  updateQuestionsOrder(questions) {
    return httpClient.patch(`${questionResource}/order`, questions);
  },
  deleteQuestionById(questionId) {
    return httpClient.delete(`${questionResource}/${questionId}`);
  },
  createSection(section) {
    return httpClient.post(`${sectionResource}`, section);
  },
  updateSection(section) {
    return httpClient.patch(`${sectionResource}`, section);
  },
  updateSectionsOrder(sections) {
    return httpClient.patch(`${sectionResource}/order`, sections);
  },
  deleteSectionById(sectionId) {
    return httpClient.delete(`${sectionResource}/${sectionId}`);
  },
  getFormResponse() {
    return httpClient.get(
      `${formResource}/form_patient_response`,
      jwt.getAuthHeaderToken()
    );
  },
  getFormResponseAdminView(formPatientId) {
    return httpClient.get(
      `${formResource}/form_patient_response_admin/${formPatientId}`
    );
  },
  generateLink(patientId) {
    return httpClient.get(`${formPatientResource}/generate_link/${patientId}`);
  }
};
