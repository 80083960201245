import httpClient from "../http-client";
import jwt from "@/common/jwt.service";
const resource = "patients";

export default {
  getRoute() {
    return `${process.env.VUE_APP_VENUSA_LIVE_API}/${resource}/file`;
  },
  sendFormAnswers(formAnswers) {
    return httpClient.post(`${resource}/form_patient`, formAnswers);
  },
  updateFormAnswers(formAnswers) {
    return httpClient.patch(
      `${resource}/form_patient`,
      formAnswers,
      jwt.getAuthHeaderToken()
    );
  },
  updateFormAnswersAdminView(formAnswers) {
    return httpClient.patch(`${resource}/form_patient_admin`, formAnswers);
  },
  getPatientsStatistics() {
    return httpClient.get(`${resource}/statistics`);
  },
  getAllPatients(filter) {
    return httpClient.get(
      `${resource}?start=${filter.start || ""}&limit=${filter.limit ||
        ""}&firstName=${filter.firstName ||
        ""}&middleName=${filter.middleName || ""}&lastName=${filter.lastName ||
        ""}&secondLastName=${filter.secondLastName ||
        ""}&email=${filter.email || ""}&gender=${filter.gender ||
        "" ||
        ""}&civilStatus=${filter.civilStatus ||
        "" ||
        ""}&children=${filter.children ||
        ""}&childPosition=${filter.childPosition ||
        ""}&birthdate=${filter.birthdate || ""}`
    );
  },
  getPatientInfoById(patientId) {
    return httpClient.get(`${resource}/detail/${patientId}`);
  },
  deletePatientById(patientId) {
    return httpClient.delete(`${resource}/${patientId}`);
  },
  updateRelative(relative) {
    return httpClient.patch(`${resource}/relative`, relative);
  },
  updateRecord(record) {
    return httpClient.patch(`${resource}/record`, record);
  },
  uploadFile(file) {
    return httpClient.post(`${resource}/upload-file`, file);
  },
  deleteFile(file) {
    return httpClient.delete(`${resource}/file/${file}`);
  },
  getPatientMultimediaFiles(patientId) {
    return httpClient.get(`${resource}/files/${patientId}`);
  }
};
