<template>
  <v-app>
    <v-main v-bind:class="{ background: backgroundDark }" height="100%">
      <router-view></router-view>
      <error-message />
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import ErrorMessage from "@/components/common/notifications/ErrorMessage.vue";

export default {
  name: "App",
  components: {
    "error-message": ErrorMessage
  },
  computed: {
    ...mapState("ux", ["backgroundDark"])
  },
  data: () => ({})
};
</script>

<style>
.v-application {
  font-family: "Montserrat", sans-serif !important;
}
.background {
  background-color: #5b58d2;
}
</style>
