import store from "@/store";
import router from "@/router";
import { HTTP_ERROR_CODES } from "@/config/constants";

const errorResponseHandler = e => {
  if (!!e.config["errorHandle"] && e.config.errorHandle === false) {
    return Promise.reject(e);
  }

  const error = e.response ? e.response.data : e;
  store.commit("http/setNewError", error);

  if (
    e?.reponse?.status &&
    (e.response.status == HTTP_ERROR_CODES.FORBIDDEN ||
      e.response.status == HTTP_ERROR_CODES.UNAUTHORIZED)
  ) {
    router.push({ name: "website-container-home" });
  }

  return Promise.reject(e);
};

export default errorResponseHandler;
