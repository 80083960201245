<template>
  <span class="full-width">
    <v-radio-group
      v-if="schema.options.length <= 5"
      v-model="value"
      class="mt-3"
      :rules="schema.required && !schema.adminView ? [rules.required] : []"
      :readonly="schema.readonly"
    >
      <v-checkbox
        v-model="selected"
        :value="option"
        v-for="(option, index) in schema.options"
        :rules="
          schema.required && !schema.adminView ? [rules.requiredChekbox] : []
        "
        :key="index + '.' + option.id"
        class="my-0"
        :readonly="schema.readonly"
        :label="option.name"
        color="primary"
      ></v-checkbox>
    </v-radio-group>
    <!-- Input for more than five options -->
    <v-autocomplete
      v-else
      :items="schema.options"
      item-text="name"
      item-vaue="id"
      v-model="value"
      :rules="schema.required && !schema.adminView ? [rules.required] : []"
      outlined
      :placeholder="schema.placeholder"
      multiple
      return-object
    >
    </v-autocomplete>
  </span>
</template>

<script>
import { abstractField } from "vue-form-generator";

export default {
  name: "multiple-choice-input",
  mixins: [abstractField],
  data() {
    return {
      selected: [],
      rules: {
        required: value => value?.length > 0 || "Campo Obligatorio",
        requiredChekbox: value => value?.length > 0
      }
    };
  },
  watch: {
    selected(newValue) {
      this.value = newValue;
    }
  },
  mounted() {
    if (this.schema.startValue) {
      this.schema.startValue.forEach(optionSelected => {
        let selectedOption = this.schema.options.find(
          option => option.id === optionSelected.option.id
        );
        this.selected.push(selectedOption);
      });
    }
  }
};
</script>
<style scoped>
.full-width {
  width: 100%;
}
</style>
