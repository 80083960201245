<template>
  <v-textarea
    v-model.trim="value"
    :disabled="disabled"
    :placeholder="schema.placeholder"
    :readonly="schema.readonly"
    :rules="
      schema.required && !schema.adminView
        ? [rules.required, rules.requiredText]
        : [rules.requiredText]
    "
    outlined
    maxlength="700"
    counter
  />
</template>

<script>
import { abstractField } from "vue-form-generator";

export default {
  name: "text-area-input",
  mixins: [abstractField],
  data() {
    return {
      rules: {
        required: value => !!value || "Campo Obligatorio",
        requiredText: value =>
          /\S/.test(value) || value == "" || "No se admiten campos en blanco"
      }
    };
  }
};
</script>
<style scoped></style>
