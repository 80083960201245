import Vue from "vue";
import Vuex from "vuex";

import modules from "./modules";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

const ls = new SecureLS({
  encryptionSecret: process.env.VUE_APP_ENCRYPTION_SECRET
});

Vue.use(Vuex);

export default new Vuex.Store({
  modules, //all modules are automatically imported
  state: {},
  mutations: {},
  actions: {
    reset({ commit }) {
      //resets state of all the modules
      Object.keys(modules).forEach(moduleName => {
        commit(`${moduleName}/reset`);
      });
    }
  },
  getters: {},
  plugins: [
    createPersistedState({
      paths: ["users.user"],
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ]
});
