import UserRepository from "./user.repository";
import WebsiteRepository from "./website.repository";
import FormRepository from "./form.repository";
import PatientRepository from "./patient.repository";

const repositories = {
  users: UserRepository,
  website: WebsiteRepository,
  form: FormRepository,
  patient: PatientRepository
};
export default {
  get: name => repositories[name]
};
