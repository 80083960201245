export const ROLES = Object.freeze({
  ADMIN: {
    id: 1
  }
});

export const STATUS = Object.freeze({
  ACTIVE: {
    id: 1
  },
  DELETED: {
    id: 2
  }
});

export const THERAPIST = Object.freeze({
  MAIN: {
    id: 1
  }
});

export const APP_SECTIONS = Object.freeze({
  DASHBOARD: {
    id: 1
  },
  PATIENTS: {
    id: 2
  },
  FORMS: {
    id: 3
  },
  WEBSITE: {
    id: 4
  }
});

export const WEBSITE_SECTIONS = Object.freeze({
  HOME: 0,
  THERAPIES: 1,
  WALL: 2
});

export const WEBSITE_COLOR_SET = Object.freeze({
  CLEAN: 0,
  SECONDARY: 1,
  PRIMARY: 2
});

export const LOGIN_SECTIONS = Object.freeze({
  LOGIN: "LOGIN",
  FORGOTTEN_PASSWORD: "FORGOTTEN_PASSWORD",
  ALTERNATIVE_LOGIN: "ALTERNATIVE_LOGIN"
});

export const WEBSITE_BUILDER_SECTIONS = Object.freeze({
  HEADER: {
    id: 1,
    websiteSection: "General",
    create: true,
    title: { required: false, visible: false },
    content: { required: false, visible: true },
    image: { required: true, visible: true },
    video: { required: false, visible: false },
    imageOrVideo: false
  },
  FOOTER: {
    id: 2,
    websiteSection: "General",
    create: false,
    title: { required: true, visible: true },
    content: { required: true, visible: true },
    image: { required: false, visible: false },
    video: { required: false, visible: false },
    imageOrVideo: false
  },
  THERAPIES_SUMMARIES: {
    id: 3,
    websiteSection: "Home",
    create: true,
    title: { required: true, visible: true },
    content: { required: true, visible: true },
    image: { required: false, visible: false },
    video: { required: false, visible: false },
    imageOrVideo: false
  },
  PROFILE_SUMMARY: {
    id: 4,
    websiteSection: "Home",
    create: false,
    title: { required: true, visible: true },
    content: { required: true, visible: true },
    image: { required: false, visible: true },
    video: { required: false, visible: true },
    imageOrVideo: false
  },
  TESTIMONIALS: {
    id: 5,
    websiteSection: "Home",
    create: true,
    title: { required: false, visible: false },
    content: { required: true, visible: true },
    image: { required: false, visible: true },
    video: { required: false, visible: true },
    imageOrVideo: true
  },
  THERAPIES: {
    id: 6,
    websiteSection: "Terapias",
    create: true,
    title: { required: true, visible: true },
    content: { required: true, visible: true },
    image: { required: false, visible: true },
    video: { required: false, visible: true },
    imageOrVideo: false
  },
  PROFILE: {
    id: 7,
    websiteSection: "Cartelera",
    create: true,
    title: { required: true, visible: true },
    content: { required: true, visible: true },
    image: { required: false, visible: true },
    video: { required: false, visible: true },
    imageOrVideo: false
  },
  PROFILE_CARD: {
    id: 8,
    websiteSection: "Cartelera",
    create: false,
    title: { required: true, visible: true },
    content: { required: true, visible: true },
    image: { required: false, visible: true },
    video: { required: false, visible: true },
    imageOrVideo: false
  }
});

export const QUESTION_TYPES = Object.freeze({
  SINGLE_CHOICE: { id: 1, name: "customesinglechoice" },
  MULTIPLE_CHOICE: { id: 2, name: "customemultiplechoice" },
  TEXT: { id: 3, name: "custometext" },
  TEXTAREA: { id: 4, name: "custometextarea" },
  DATE: { id: 5, name: "customedate" },
  SWITCH: { id: 6, name: "switch" }
});

export const KINSHIPS = Object.freeze({
  ME: { id: 1 },
  MOTHER: { id: 2 },
  FATHER: { id: 3 },

  MATERNAL_GRANDMOTHER: { id: 4 },
  MATERNAL_GRANDFATHER: { id: 5 },
  PATERNAL_GRANDMOTHER: { id: 6 },
  PATERNAL_GRANDFATHER: { id: 7 },

  MOTHER_MATERNAL_GRANDMOTHER: { id: 8 },
  FATHER_MATERNAL_GRANDMOTHER: { id: 9 },
  MOTHER_MATERNAL_GRANDFATHER: { id: 10 },
  FATHER_MATERNAL_GRANDFATHER: { id: 11 },

  MOTHER_PATERNAL_GRANDMOTHER: { id: 12 },
  FATHER_PATERNAL_GRANDMOTHER: { id: 13 },
  MOTHER_PATERNAL_GRANDFATHER: { id: 14 },
  FATHER_PATERNAL_GRANDFATHER: { id: 15 }
});

export const ERROR_CODES = Object.freeze({
  SERVER_ERROR: "Ocurrió un error inesperado",
  NETWORK_ERROR: "Error en red detectado, revise su conexión a internet",
  WRONG_CREDENTIALS: "Credenciales incorrectas",
  UPDATE_FORM_ANSWER:
    "Ocurrió un error al modificar la respuesta del formulario",
  PATIENT_NOT_FOUND: "El consultante no se encuentra registrado en el sistema",
  FORM_CREATION: "Error en la creación del formulario",
  FORM_NOT_FOUND:
    "El formulario solicitado no se encuentra registrado en el sistema",
  FORM_PATIENT_NOT_FOUND:
    "Las respuestas del formulario y consultante solicitados no se encuentran registrados en el sistema",
  QUESTION_UPDATE: "Ocurrió un error al modificar la pregunta",
  QUESTION_NOT_FOUND: "La pregunta no se encuentra registrada en el sistema",
  QUESTION_DELETION: "Ocurrió un error al eliminar la pregunta",
  QUESTION_TYPES_NOT_FOUND:
    "No se encuentra registrada en el sistema ningun tipo o clasificación de pregunta",
  SECTION_NOT_FOUND: "La sección no se encuentra registrada en el sistema",
  SECTION_DELETION: "Ocurrió un error al eliminar la sección",
  PATIENT_UPDATE: "Ocurrió un error al actualizar el consultante",
  USER_NOT_FOUND: "El usuario no se encuentra registrado en el sistema",
  REGISTER_PATIENT_AND_FORM_RESPONSES:
    "Ocurrió un error al crear al consultante y registrar sus respuestas del formulario",
  UPDATE_PATIENT_AND_FORM_RESPONSES:
    "Ocurrió un error al actualizar al consultante y sus respuestas del formulario",
  USED_USERNAME:
    "El usuario introducido ya se encuentra registrado en el sistema",
  USED_EMAIL: "El correo introducido ya se encuentra asociado a otro usuario",
  USER_CREATION: "Ocurrió un error en la creación del usuario",
  NOT_MATCHING_PASSWORD: "Las contraseñas no coinciden",
  FILE_NOT_AN_IMAGE: "El archivo no es una imagen",
  EMPTY_FILES_REQUEST: "No se encuentran archivos en la petición",
  WEBSITE_CONTENT_CREATION:
    "Ocurrió un error en la creación del contenido del website",
  WEBSITE_CONTENT_NOT_FOUND:
    "El contenido del website no se encuentra registrado en el sistema",
  WEBSITE_CONTENT_DELETION:
    "Ocurrió un error en la eliminación del contenido del website",
  WEBSITE_CONTENT_UPDATE:
    "Ocurrió un error en la modificación del contenido del website",
  WEBSITE_CONTENTS_UPDATE:
    "Ocurrió un error en la modificación de los contenidos del website",
  WEBSITE_SECTION_NOT_FOUND:
    "La sección del website solicitada no se encuentra registrada en el sistema",
  NO_WEBSITE_SECTIONS:
    "No se encuentra registrada en el sistema ninguna sección del webiste"
});

export const HTTP_ERROR_CODES = Object.freeze({
  FORBIDDEN: 403,
  UNAUTHORIZED: 401
});
